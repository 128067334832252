import React from 'react'
import { css } from 'emotion'
import {Dropdown} from 'react-bootstrap'

import FiltersContainer from 'app/orderTool/components/sidebar/filters/FiltersContainer'

const OrderSidebar = (props) => {

  const baseUrl = process.env.REACT_APP_API_URL
  const currentUrlEncode = encodeURIComponent(window.location.href);

  return (
    <div>

      <Dropdown className={css(style.center)}>
        <Dropdown.Toggle variant="success" id="dropdown-basic" className={css(style.buttonQuotation)}>
          Créer un devis
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item target="_parent" href={baseUrl+'/e28XmZh3/proorder/create?redirect_url_encode='+currentUrlEncode}>Plateaux-repas</Dropdown.Item>
          <Dropdown.Item target="_parent" href={baseUrl+'/e28XmZh3/catererorder/create?redirect_url_encode='+currentUrlEncode}>Traiteur</Dropdown.Item>
          <Dropdown.Item target="_parent" href={baseUrl+'/e28XmZh3/breakfastorder/create?redirect_url_encode='+currentUrlEncode}>Petit-déjeuner</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>

      <FiltersContainer />

    </div>
  )

}

const style = {
  center:{
    textAlign:'center'
  },
  buttonQuotation:{
    margin: '1.0rem',
    padding: '0.5rem 3rem',
    borderRadius: '0',
    fontSize: '0.8rem',
    backgroundColor: '#439b5c !important',
    border: 'none',
    textTransform: 'uppercase',
    fontWeight: 'bold'
  },
  buttonCard:{
    margin: '0.5rem 1rem',
    padding: '0.5rem 3rem',
    borderRadius: '0',
    fontSize: '0.8rem',
    backgroundColor: '#323232 !important',
    border: 'none',
    textTransform: 'uppercase',
    fontWeight: 'bold'
  },
  para:{
    color: '#c3c3c3',
    textAlign: 'center',
    fontSize: '0.8rem',
    marginBottom: '0'
  }
}

export default OrderSidebar
