import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { DirectionsRenderer } from 'react-google-maps'

const Direction = (props) => {

  const {
    deliveries,
    color,
    handleResult,
    isLoading
  } = props

  const [init, setInit] = useState(false);
  const [directions, setDirections] = useState();
  const [options, setOptions] = useState({
    draggable: false,
    markerOptions: {
      icon: {
        path: 'M-20,0a20,20 0 1,0 40,0a20,20 0 1,0 -40,0',
        fillColor: color,
        fillOpacity: 0.8,
        scale: 0.3,
        strokeColor: color,
        strokeWeight: 1
      }
    }
  });

  // Stock les livraisons pour pouvoir les comparer après l'intialisation de la map pour recharger l'itinéraire si besoin
  const [deliveriesBis, setDeliveriesBis] = useState(deliveries);

  useEffect(() => {
    
    let reloadRm = false;
    // Compare les livraisons, si non identiques recharge l'itinéraire
    if (deliveriesBis.length !== deliveries.length) {
      reloadRm = true;
    }

    if ((!init || reloadRm) && isLoading === false) {
      const DirectionsService = new window.google.maps.DirectionsService()
      let waypts = []

      deliveries.forEach((element, index) => {
        waypts.push({
          location: new window.google.maps.LatLng(element.delivery.lat, element.delivery.lng),
          stopover: true
        })
      })

      const origin = new window.google.maps.LatLng(45.77569179999999, 4.803365299999996);
      let destination = origin;
      if (waypts.length > 1) {
        destination = waypts.splice(-1)[0].location;
      }

      DirectionsService.route({
        origin: new window.google.maps.LatLng(45.77569179999999, 4.803365299999996),
        waypoints: waypts,
        destination: destination,
        travelMode: window.google.maps.TravelMode.DRIVING,
        avoidTolls: true
      }, (result, status) => {
        if (status === window.google.maps.DirectionsStatus.OK) {
          setDirections(result)
          setOptions({
            draggable: false,
            markerOptions: {
              icon: {
                path: 'M-20,0a20,20 0 1,0 40,0a20,20 0 1,0 -40,0',
                fillColor: color,
                fillOpacity: 0.8,
                scale: 0.4,
                strokeColor: color,
                strokeWeight: 1
              }
            }
          })
          handleResult(result);
          setInit(true);
    
        } else {
          console.error(`error fetching directions ${result}`);
        }
      });      
    }
    // eslint-disable-next-line
  }, [init, deliveries, deliveriesBis, isLoading]);

  return (
      <DirectionsRenderer directions={directions} options={options}/>
  )

}

Direction.propTypes = {
  deliveries: PropTypes.array,
  color: PropTypes.string
}
Direction.defaultProps = {
  color: 'red'
}

export default Direction
