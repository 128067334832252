import { injectGlobal } from 'emotion'

const Global = (props) => {
}

injectGlobal`
  body{
    background-color: #ecf5f4;
  }
  a{
    -moz-transition: 0.5s;
    -o-transition: 0.5s;
    -webkit-transition: 0.5s;
    transition: 0.5s;
  }

  /* Position and sizing of burger button */
  .bm-burger-button {
    position: fixed;
    width: 26px;
    height: 20px;
    right: 25px;
    top: 15px;
  }

  /* Color/shape of burger icon bars */
  .bm-burger-bars {
    background: white;
  }

  /* Color/shape of burger icon bars on hover*/
  .bm-burger-bars-hover {
    background: black;
  }

  /* Position and sizing of clickable cross button */
  .bm-cross-button {
    height: 24px;
    width: 24px;
  }

  /* Color/shape of close button cross */
  .bm-cross {
    background: white;
  }

  /*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */
  .bm-menu-wrap {
    position: fixed;
    height: 100%;
  }

  /* General sidebar styles */
  .bm-menu {
    background: #429853;
    padding: 2.5em 1.5em 0;
    font-size: 1.15em;
  }

  /* Morph shape necessary with bubble or elastic */
  .bm-morph-shape {
    fill: #429853;
  }

  /* Wrapper for item list */
  .bm-item-list {
    color: #b8b7ad;
    padding: 0;
  }

  /* Individual item */
  .bm-item {
    display: inline-block;
  }

  /* Styling of overlay */
  .bm-overlay {
    background: rgba(0, 0, 0, 0.3);
  }

  .bm-item-list a {
    padding: 0.8em 0em;
  }

  #elastic span {
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 0.75em;
  }

  .bm-item-list a span {
    margin-left: 10px;
    font-weight: 700;
  }
  .bm-menu a {
    color: white;
    &:hover{
      text-decoration:none;
      opacity: 0.7;
    }
  }

  .fancy label {
    display: inline-flex;
    align-items: baseline;
    font-size:0.8rem;
    line-height: 1;
    margin-bottom: 0;
    margin-left: 0.5rem;
  }
  .fancy p{
    margin-bottom: 0;
  }
  .fancy label input[type=checkbox],
  .fancy label input[type=radio] {
    position: relative;
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    font-size: inherit;
    width: 1em;
    margin: 0;
    color: inherit;
    outline: none;
    font-family: 'Font Awesome 5 Pro';
    transition: 300ms ease-out;
  }
  .fancy label input[type=checkbox]::after,
  .fancy label input[type=radio]::after {
    content: '';
    border: 1px solid #ecebeb;
    background-color: #f8f8f6;
    display: inline-block;
    text-align: center;
    width: 1rem;
    height: 1rem;
  }
  .fancy label input[type=checkbox]:checked::after,
  .fancy label input[type=radio]:checked::after {
    font-weight: 900;
  }
  .fancy label input[type=checkbox]:active,
  .fancy label input[type=radio]:active {
    -webkit-transform: scale(0.6);
            transform: scale(0.6);
  }
  .fancy label input[type=checkbox] + span,
  .fancy label input[type=radio] + span {
    margin-left: 0.8rem;
  }
  .fancy label input[type=checkbox]:checked::after {
    background-color: green;
    border: 1px solid #ecebeb;
    -webkit-print-color-adjust: exact;

  }
  .fancy label input[type=radio]:checked::after {
    background-color: green;
    border: 1px solid #ecebeb;
    -webkit-print-color-adjust: exact;

  }

  .fade-enter {
    opacity: 0.01;
  }

  .fade-enter.fade-enter-active {
    opacity: 1;
    transition: opacity 300ms ease-in;
  }

  .fade-leave {
    opacity: 1;
  }

  .fade-leave.fade-leave-active {
    opacity: 0.01;
    transition: opacity 200ms ease-in;
  }

  .react-autosuggest__container {
    position: relative;
  }

  .react-autosuggest__input {
    border-radius: 0;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
  }

  .react-autosuggest__input--focused {
    outline: none;
  }

  .react-autosuggest__suggestions-container {
    display: none;
  }

  .react-autosuggest__suggestions-container--open {
    display: block;
    position: absolute;
    width: 280px;
    border: 1px solid #aaa;
    background-color: #fff;
    font-family: Helvetica, sans-serif;
    font-weight: 300;
    font-size: 16px;
    z-index: 2;
  }

  .react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  .react-autosuggest__suggestion {
    cursor: pointer;
    padding: 10px 20px;
  }

  .react-autosuggest__suggestion--highlighted {
    background-color: #ddd;
  }

  //Filter & input time
  @media only screen and (max-width: 767px){
    .react-datepicker__input-container{
      width: 100%;
      margin-bottom: 10px;
    }
    .rc-time-picker{
      display: block;
      margin-bottom: 5px;
    }
  }

  @media print {
    .no-print{
      display:none;
    }
    .exact-print{
      -webkit-print-color-adjust: exact;
    }
    .print-fw{
      width: 100%;
      flex: 0 0 100%;
      max-width: 100%;
    }
    .break-page{
      page-break-after: always
    }
  }
  .rbc-month-view{
    height: 500px !important;
  }
  .rbc-month-row {
    overflow: inherit !important;
    height: auto !important;
  }
  .rbc-event{
    height: auto !important;
  }
  .rbc-row {
    display: flex;
    flex-direction: row;
  }
  .rc-time-picker-input{
    background-color: #f8f8f6;
  }
  .form-control{
    background-color: #f8f8f6;
    font-size: 0.8rem;
  }
  .page-item.active .page-link{
    background-color: #439b5c;
    border-color: #439b5c;
  }
  .page-link{
    color: #439b5c;
  }
  .tippy-box {
    position: relative;
    background-color: #333;
    padding: 0.8rem;
    color: #fff;
    font-size: 0.8rem;
    border-radius: 4px;
    line-height: 1.4;
    outline: 0;
    transition-property: transform,visibility,opacity;
  }
  .dropdown-item{
    font-size: 0.8rem;
  }
`

export default Global
