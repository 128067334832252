import React  from 'react'
import { Link } from 'react-router-dom'
import {Row, Col} from 'react-bootstrap'
import { css } from 'emotion'
import moment from 'moment'
import { faMap, faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { useFilters } from 'app/deliveryTool/context/FiltersProvider'
import { useRoadmaps } from 'app/deliveryTool/context/RoadmapsProvider'

import LeMoulinApi from 'app/universal/utils/LeMoulinApi'
import PeriodStartAt from 'app/universal/utils/PeriodStartAt'

const ListRoadMaps = (props) => {

  const filters = useFilters();

  const {
    roadmaps,
    date,
  } = props

  const rmData  = useRoadmaps();

  // Suppression d'une rm
  const deleteRoadmap = async(roadmapId) => {
    if (window.confirm("Etes-vous sûr de supprimer cette tournée ?")) {
      let index = rmData.roadmaps.findIndex(function(element) {
        return parseInt(element.id) === parseInt(roadmapId);
      }, [roadmapId])
      let roadmapsClone = [...rmData.roadmapsInit]

      await LeMoulinApi.request.roadmap.delete(roadmapId)

      updateDeliveries(roadmapsClone[index].deliveries)
      roadmapsClone.splice(index, 1)
      rmData.setRoadmapsInit(roadmapsClone)
    }
  }

  // Replace les livraisons d'une rm dans les non attribuées
  const updateDeliveries = (rmDeliveries) => {
    const [deliveries,recoveries,mustUpdated] = [[...rmData.deliveries], [...rmData.recoveries], {recoveries: false, deliveries: false}]
    rmDeliveries.forEach((rmDelivery, i) => {

      if (rmDelivery.recovery) {
        recoveries.push(rmDelivery.delivery)
        mustUpdated.recoveries = true
      } else {
        deliveries.push(rmDelivery.delivery)
        mustUpdated.deliveries = true
      }
    })
    mustUpdated.deliveries && rmData.setDeliveries(deliveries)
    mustUpdated.recoveries && rmData.setRecoveries(recoveries)

  }



  return (
    <div>

      {roadmaps.length > 0 ?
        <div>
          <div className={css(styles.center)}>
            <h2 className={css(styles.title)}>Tournées des livreurs</h2>
          </div>
          {roadmaps.map((roadmap, index) =>
            <Row key={roadmap.id} noGutters>
              <Col>
                <Link to={{
                    pathname: `/itineraires/${roadmap.id}`,
                    search: window.location.search
                  }} className={css(styles.block, {borderLeft: '3px solid green'})} >
                  <h3 className={css(styles.statut)}>Tournée <PeriodStartAt item={roadmap.start_hour} /></h3>

                  <p className={css(styles.para)}>
                    <b>{roadmap.name ? roadmap.name : 'Pas de nom attribué'}</b> - {roadmap.delivery_man && roadmap.delivery_man.firstname ? roadmap.delivery_man.firstname : 'Aucun livreur (livreur supprimé ?)'}
                    <br/><br/>
                    Départ à <b><i>{roadmap.start_hour ? moment(roadmap.start_hour).format('HH:mm') : 'NC'}</i></b> pour un parcours de <b><i>{roadmap.distance ? roadmap.distance / 1000 : 'NC'} km</i></b><br/>
                    Contient <b><i>{roadmap.deliveries.length}</i></b> { roadmap.deliveries.length > 1 ? 'livraisons' : 'livraison' } -
                    <span onClick={(event) => {event.preventDefault();filters.setShowRoadmap(roadmap.id)}} className={css(styles.map)}><FontAwesomeIcon icon={faMap} /></span>
                    <span className={css(styles.trash)} onClick={(event) => {event.preventDefault();deleteRoadmap(roadmap.id)}} ><FontAwesomeIcon icon={faTrash} /></span>
                  </p>
                </Link>
              </Col>
            </Row>
          )}
          </div>
        :
        <div className={css(styles.center)}>
          <h2 className={css(styles.title)}>Aucune tournée créée<br/>pour le {moment(date).format('DD/MM/YYYY')} </h2>
        </div>
      }
    </div>

  )

}

const styles = {
  title: {
    color: '#484b4c',
    fontSize: '1.5rem',
    margin: '1.5rem 0 1.0rem 0',
    fontWeight: 'normal'
  },
  center: {
    textAlign: 'center'
  },
  block: {
    color: '#484b4c',
    display: 'block',
    textDecoration: 'none',
    backgroundColor: 'white',
    margin: '0.3rem 0.5rem',
    padding: '0.5rem',
    cursor: 'pointer',
    ':hover': {
      opacity: '0.6',
      transition: '300ms',
      textDecoration: 'none',
      color: 'inherit'
    }
  },
  statut: {
    textTransform: 'uppercase',
    fontSize: '0.6rem',
    marginBottom: '0rem'
  },
  para:{
    marginBottom: '0rem',
    fontSize: '0.8rem'
  },
  map: {
    position: 'absolute',
    backgroundColor: '#429853',
    top: '0.3rem',
    right: '0.5rem',
    color: 'white',
    textDecoration: 'none',
    cursor: 'pointer',
    fontWeight: 'normal',
    padding: '0.5rem',
    width: '30px',
    ':hover': {
      backgroundColor: '#212529',
      transition: '300ms'
    }
  },
  trash: {
    position: 'absolute',
    backgroundColor: '#ff5050',
    bottom: '0.3rem',
    right: '0.5rem',
    color: 'white',
    textDecoration: 'none',
    cursor: 'pointer',
    fontWeight: 'normal',
    padding: '0.5rem',
    width: '30px',
    ':hover': {
      backgroundColor: '#212529',
      transition: '300ms'
    }
  }

}

ListRoadMaps.propTypes = {

}

export default ListRoadMaps
