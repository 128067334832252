import React, {Component} from 'react'
import log from './Logger'

export default class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    log.error({error, info})
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <h1>L'application vient de planter. Il faut recharger :/. Cette version est instable, il faut y aller doucement.</h1>;
    }

    return this.props.children;
  }
}
