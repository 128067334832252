import axios from "axios";
import oauth from "axios-oauth-client";
import moment from "moment";
import { toast } from "react-toastify";
import { css } from "emotion";
import Qs from "qs";

const launchSuccessNotif = (notif, msg) => {
  toast.update(notif, {
    render: msg,
    type: toast.TYPE.SUCCESS,
    className: css({
      transition: "transform 0.6s",
      transform: "rotateY(360deg)"
    })
  });
};

const launchFailNotif = (notif, msg) => {
  toast.update(notif, {
    render: msg,
    type: toast.TYPE.ERROR,
    className: css({
      transition: "transform 0.6s",
      transform: "rotateY(360deg)"
    })
  });
};

const leMoulinApi = {
  request: {
    tender: {
      gets: filters => {
        return axios.get(
          process.env.REACT_APP_API_URL +
            `/api/pro/tenders.json`
        );
      },
    },
    logger: {
      post: data => {
        return axios.post(
          process.env.REACT_APP_API_URL + "/api/loggers.json",
          data
        );
      }
    },
    roadmap: {
      get: id => {
        return axios.get(
          process.env.REACT_APP_API_URL + "/api/pro/roadmaps/" + id + ".json"
        );
      },
      gets: filters => {
        const thisDate = moment(filters.date).format("YYYY-MM-DD");
        return axios.get(
          process.env.REACT_APP_API_URL +
            `/api/pro/roadmaps.json?filters[date]=` +
            thisDate
        );
      },
      post: data => {
        return axios.post(
          process.env.REACT_APP_API_URL + "/api/pro/roadmaps.json",
          data
        );
      },
      put: (id, data) => {
        return axios.put(
          process.env.REACT_APP_API_URL + "/api/pro/roadmaps/" + id + ".json",
          data
        );
      },
      delete: id => {
        return axios.delete(
          process.env.REACT_APP_API_URL + "/api/pro/roadmaps/" + id + ".json"
        );
      },
      deliveries: {
        add: (roadmapId, data) => {
          return axios.post(
            process.env.REACT_APP_API_URL +
              "/api/pro/roadmaps/" +
              roadmapId +
              "/deliveries.json",
            data
          );
        },
        remove: (roadmapId, id) => {
          return axios.delete(
            process.env.REACT_APP_API_URL +
              "/api/pro/roadmaps/" +
              roadmapId +
              "/deliveries/" +
              id +
              ".json"
          );
        }
      }
    },
    delivery: {
      get: id => {},
      gets: filters => {
        const thisDate = moment(filters.date).format("YYYY-MM-DD");
        return axios.get(
          process.env.REACT_APP_API_URL +
            `/api/pro/deliveries.json?filters[deliveryDate]=` +
            thisDate +
            "&order_by[arrivedAtStart]=ASC&no_attributed=1"
        );
      },
      post: data => {},
      put: (id, data) => {
        return axios.put(
          process.env.REACT_APP_API_URL + "/api/pro/deliveries/" + id + ".json",
          data
        );
      },
      delete: id => {}
    },
    recovery: {
      get: id => {},
      gets: filters => {
        const thisDate = moment(filters.date).format("YYYY-MM-DD");
        return axios.get(
          process.env.REACT_APP_API_URL +
            `/api/pro/deliveries/recoveries.json?filters[deliveryDate]=` +
            thisDate +
            "&no_attributed=1"
        );
      },
      post: data => {},
      put: (id, data) => {},
      delete: id => {}
    },
    order: {
      // gets: filters => {
      //   return axios.get(
      //     process.env.REACT_APP_API_URL + "/api/pro/orders.json",
      //     { params: { filters: filters } }
      //   );
      // },
      gets: (data) => {
        return axios.get(process.env.REACT_APP_API_URL+'/api/pro/orders.json', {params:data})
      },
      missingFieldsCustomerNotification: (orderId, orderType) => {
        return axios.post(process.env.REACT_APP_API_URL+'/api/pro/orders/missings/fields/customers/notifications.json', {orderId: orderId, orderType: orderType})
      },
      mealtray: {
        put: (id, data) => {
          return axios.put(process.env.REACT_APP_API_URL+'/api/pro/orders/'+id+'/mealtray.json', data)
        },
      },
      caterer: {
        put: (id, data) => {
          return axios.put(process.env.REACT_APP_API_URL+'/api/pro/orders/'+id+'/caterer.json', data)
        },
      },
      breakfast: {
        put: (id, data) => {
          return axios.put(process.env.REACT_APP_API_URL+'/api/pro/orders/'+id+'/breakfast.json', data)
        },
      },
      v2: {
        put: (id, data) => {
          return axios.put(process.env.REACT_APP_API_URL+'/api/pro/orders/'+id+'/v2.json', data)
        },
      },
      compositionSheets: {
        gets: (filters) => {
          return axios.get(process.env.REACT_APP_API_URL+'/api/pro/orders/compositionsheets.json', {params:{filters:filters}})
        }
      },
      compositionSheetsV2: {
        gets: (filters) => {
          return axios.get(process.env.REACT_APP_API_URL+'/api/pro/orders/v2/compositionsheets.json', {params:{filters:filters}})
        }
      },
    },
    mealtray: {
      put: (id, data) => {
        return axios.put(process.env.REACT_APP_API_URL+'/api/pro/orders/'+id+'/mealtray.json', data)
      },
    },
    caterer: {
      put: (id, data) => {
        return axios.put(process.env.REACT_APP_API_URL+'/api/pro/orders/'+id+'/caterer.json', data)
      },
    },
    breakfast: {
      put: (id, data) => {
        return axios.put(process.env.REACT_APP_API_URL+'/api/pro/orders/'+id+'/breakfast.json', data)
      },
    },
    v2: {
      put: (id, data) => {
        return axios.put(process.env.REACT_APP_API_URL+'/api/pro/orders/'+id+'/v2.json', data)
      },
    },
    deliveryMan: {
      get: id => {},
      gets: filters => {
        return axios.get(
          process.env.REACT_APP_API_URL +
            "/api/pro/deliverymen.json?order_by[firstname]=ASC"
        );
      },
      post: data => {},
      put: (id, data) => {},
      delete: id => {},
      // Envoie des roadmaps sur workchat
      sendRoadmapsOnWorkchat: (deliveryDate, deliveryManId) => {
        return axios.post(
          process.env.REACT_APP_API_URL +
            "/api/pro/deliverymen/sendroadmapsonworkchats.json",
            {deliveryDate: deliveryDate, deliveryManId: deliveryManId}
        );
      },
      // roadmap recup par token
      roadmaps: {
        getByToken: (token) => {
          return axios.post(process.env.REACT_APP_API_URL+'/api/pro/deliverymen/roadmapstokens.json',
            {token: token}
          )
        }
      }
    },
    product: {
      get: (id) => {
        return axios.get(process.env.REACT_APP_API_URL+'/api/products/'+id+'.json')
      },
      gets: (filters) => {
        return axios.get(process.env.REACT_APP_API_URL+'/api/products.json?limit=100', {params:{filters:filters}})
      }
    },
    // Pour l'exemple
    sample: {
      get: id => {},
      gets: filters => {},
      post: data => {},
      put: (id, data) => {},
      delete: id => {}
    }
  },
  getToken: async (username, password) => {
    const getOwnerCredentials = oauth.client(axios.create(), {
      url: process.env.REACT_APP_API_URL + "/oauth/v2/token",
      grant_type: "password",
      client_id: "2_dqs6543vc6c7vt",
      client_secret: "ikjuh8k,bn5f46as",
      username: username,
      password: password
    });
    const credentials = await getOwnerCredentials();
    return credentials.access_token;
  }
};

let notif = null;

// Gere la notif et le access token
axios.interceptors.request.use(
  function(config) {
    notif = toast.info("Chargement ...");

    const url = config.url;
    if (url.includes("?")) {
      config.url = config.url + "&apiKey=123";
    } else {
      config.url = config.url + "?apiKey=123";
    }

    config.paramsSerializer = params => {
      // Qs is already included in the Axios package
      return Qs.stringify(params, {
        arrayFormat: "brackets",
        encode: false
      });
    };

    let token = window.localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    } else {
      window.localStorage.removeItem("token");
    }

    return config;
  },
  function(error) {
    return Promise.reject(error);
  }
);

// Gere les erreurs et la notif
axios.interceptors.response.use(
  function(response) {
    let msg = "Mise à jour réussi";
    if (response.data && response.data.success === false) {
      msg = response.data.message ? response.data.message : "Une erreur est survenue";
      launchFailNotif(notif, msg);
      return response.data;
    }
    if (response.data && response.data.success && response.data.message) {
      msg = response.data.message;
    }
    launchSuccessNotif(notif, msg);
    return response.data;
  },
  function(error) {
    let errorMsg = "Une erreur est survenue";
    if (error.response && error.response.data && error.response.data.message) {
      errorMsg = error.response.data.message;
    }
    launchFailNotif(notif, errorMsg);

    if (error.response) {
      // Erreur d'authentification
      if (error.response && error.response.status === 401) {
        window.localStorage.removeItem("token");
        window.location.reload();
        return;
      }
      // Mauvaise requete
      if (
        error.response &&
        error.response.data &&
        error.response.status === 400
      ) {
        let data = error.response.data;
        data.error = true;
        return data;
      }
    } else {
      console.log("Error", error.message);
    }
    console.log(error.config);

    return Promise.reject(error);
  }
);

export default leMoulinApi;
