import log from 'loglevel'
import remote from 'loglevel-plugin-remote'
// import { LogglyTracker } from 'loggly-jslogger';

const customJSON = log => ({
 msg: log.message,
 level: log.level.label,
 stacktrace: log.stacktrace
});

remote.apply(log, {
  format: customJSON,
  url: 'https://localhost:8000/api/loggers?apiKey=123',
  token: window.localStorage.getItem('token'),
});

log.enableAll();

// const log = new LogglyTracker();
//
// log.push({ 'logglyKey': 'aa128d98-7114-4f2b-88a6-c5e09465dc7e' });

export default log;
