import React from 'react'
import { css } from 'emotion'

import moment from 'moment'
import {Dropdown} from 'react-bootstrap'


const GenerateProdCardButton = (props) => {

  const {
    startDate,
    endDate,
    status
  } = props.value

  const startDateFormat = moment(startDate).format('DD/MM/YYYY')
  const endDateFormat = moment(endDate).format('DD/MM/YYYY')

  const baseUrl = process.env.REACT_APP_API_URL

  let statusFormat = [];

  if(status == 'all_valid'){
    let code = ['waiting', 'confirmed', 'billable'];
    code.forEach(element => {
      statusFormat += '&filter[orders_status][value][]='+element+'';
    });
  }else{
    statusFormat += '&filter[orders_status][value][]='+status+'';
  }

  const prmMultipole = '?startDate='+moment(startDate).format('YYYY-MM-DD')+'&endDate='+moment(endDate).format('YYYY-MM-DD')+''+statusFormat+''

  const prmType = 'filter[delivery_date][value][end]='+endDateFormat+'&filter[delivery_date][value][start]='+startDateFormat+'&filter[establishment][value]='+statusFormat+''

  const prmTypeV2 = 'filter[cart__deliveryInfo__deliveryDate][value][end]='+endDateFormat+'&filter[cart__deliveryInfo__deliveryDate][value][start]='+startDateFormat+'&filter[establishment][value]='+statusFormat+''

  return (
    <div>
    { startDate && endDate ?
      <Dropdown drop={'down'} className={css(style.center)}>
        <Dropdown.Toggle variant="success" className={css(style.buttonCard)}>
          Créer fiche production du <br/> {startDateFormat} au {endDateFormat}<br/> pour
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item href={'/fiche-production-multipole-v2'+prmMultipole+'&activity=all'} target="_blank">
              Multipôle
          </Dropdown.Item>
          <Dropdown.Item href={'/fiche-production-multipole-v2'+prmMultipole+'&activity=v2'} target="_blank">
              Plateaux-repas
          </Dropdown.Item>
          <Dropdown.Item href={'/fiche-production-multipole-v2'+prmMultipole+'&activity=caterer'} target="_blank">
              Traiteur
          </Dropdown.Item>
          <Dropdown.Item href={'/fiche-production-multipole-v2'+prmMultipole+'&activity=breakfast'} target="_blank">
              Petit-déjeuner
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      :
      <p className={css(style.msgGenerate)}>Pour générer des fiches de production merci de sélectionner vos dates ci-dessus.</p>
    }
    </div>
  );
};

const style = {
  msgGenerate:{
    textAlign: 'center',
    border: 'none',
    fontSize: '0.9rem',
    margin: 'auto',
    marginTop: '4.0rem',
    padding: '0.5rem',
    width: '80%',
    color: 'grey !important',
    borderRadius: '0',
    marginBottom: '2.0rem',

  },
  buttonCard:{
    backgroundColor: '#dedede !important',
    border: 'none',
    fontSize: '0.9rem',
    marginTop: '4.0rem',
    padding: '0.5rem',
    width: '80%',
    color: 'black !important',
    borderRadius: '0',
    marginBottom: '2.0rem',
    '&:hover': {
      backgroundColor:'#222d32 !important',
      color: 'white !important',
    }
  },
  center:{
    textAlign: 'center'
  },

}

export default GenerateProdCardButton
