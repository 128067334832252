import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { css } from "emotion";
import moment from "moment";

import Translate from "app/universal/utils/Translate";
import UpdateDataApi from "app/universal/utils/UpdateDataApi";
import UrlQuotation from "app/universal/utils/UrlQuotation";
import Reviews from "app/universal/utils/Reviews";

const Recovery = (props) => {
  const {
    delivery,
    colorDeliveryMan,
    filters,
    handler,
    getUrl,
    theme,
    roadmapId,
    handleReallocation
  } = props;

  const reallocation = (delivery) => {
    if (!delivery.recovery) {
      return alert("Ce n'est pas une récupération !");
    }
    else {
      if (window.confirm("Voulez-vous vraiment réaffecter cette livraison ?")) {
        handleReallocation();
      }
    }
  }
  return (
    <div>
      {theme === "light" ? (
        <Container
          className={css(styles.recovery, {
            border: filters.showDelivery === delivery.id && "3px green solid",
          })}
          name={"delivery-" + delivery.id}
          fluid
        >
          <Row className={css(styles.verticalAlign)}>
            {delivery.image == null ? (
              <Col xs={12} md={2} className={css(styles.col, styles.noImg)}>
                Pas d'image
                <UrlQuotation url={delivery.info.quotation_v1_url} old={true} />
                <UrlQuotation url={delivery.info.quotation_url} />
              </Col>
            ) : (
              <Col
                xs={12}
                md={2}
                className={css(styles.col, styles.img, {
                  backgroundImage:
                    'url("' + getUrl + "/" + delivery.image.path + '")',
                })}
              >
                <div
                  className={css(styles.blockColor, {
                    backgroundColor: "" + colorDeliveryMan + "",
                  })}
                >
                  {delivery.alias}
                </div>
                <UrlQuotation url={delivery.info.quotation_v1_url} old={true} />
                <UrlQuotation url={delivery.info.quotation_url} />
              </Col>
            )}
            <Col xs={12} md={10} className={css(styles.col)}>
              <Reviews note={delivery.note} />
              <h3 className={css(styles.statut)}>
                Récupération <Translate item={delivery.type} /> du
                {moment(filters.date).format("YYYY-MM-DD") !==
                moment(delivery.delivery_date).format("YYYY-MM-DD") ? (
                  <span className={css(styles.warning)}>
                    {" "}
                    {moment(delivery.delivery_date).format("D-M-Y")}
                  </span>
                ) : (
                  " " + moment(delivery.delivery_date).format("D-M-Y")
                )}{" "}
                -{" "}
                <b>
                  {delivery.firstname} {delivery.lastname}
                </b>
              </h3>
              <p className={css(styles.para)}>
                <span className={css(styles.company)}>{delivery.company}</span>
                <br />
                {delivery.street_number} {delivery.street}{" "}
                {delivery.postal_code} {delivery.city}
                <br />
                <i>{delivery.address_complement}</i>
                <br />
                <br />
              </p>
              <Row noGutters>
                <Col xs={12} md={4}>
                  <p className={css(styles.para)}>Récupération :</p>
                </Col>
                <Col xs={12} md={4}>
                  <UpdateDataApi
                    value={delivery.end_at}
                    id={delivery.id}
                    name={"end_at"}
                    api={"delivery"}
                    input={"times"}
                    handler={handler}
                  />
                </Col>
              </Row>
              <Row noGutters>
                <Col xs={12} md={12}>
                  <UpdateDataApi
                    value={delivery.recover_information}
                    id={delivery.id}
                    name={"recover_information"}
                    api={"delivery"}
                    input={"text"}
                    placeholder={"Ajouter un commentaire..."}
                    handler={handler}
                  />
                </Col>
                {roadmapId !== null && 
                  <Button
                    className={css(styles.reallocation)}
                    onClick={() => {
                      reallocation(delivery)
                    }}
                  >
                    A réattribuer
                  </Button>
                }
              </Row>
            </Col>
          </Row>
        </Container>
      ) : theme === "print" ? (
        <Container className={css(styles.recoveryPrint)} fluid>
          <Row className={css(styles.verticalAlign)}>
            <Col xs={12} md={6} className={css(styles.col)}>
              <h3 className={css(styles.statut)}>Récupération</h3>
              <p className={css(styles.para)}>
                <span className={css(styles.company)}>{delivery.company}</span>
                <br />
                {delivery.street_number} {delivery.street}{" "}
                {delivery.postal_code} {delivery.city}
                <br />
                <i>{delivery.address_complement}</i>
                <br />
                <i>
                  {delivery.firstname} {delivery.lastname} / {delivery.phone}{" "}
                  <br />
                </i>
              </p>
            </Col>
            <Col xs={12} md={3} className={css(styles.col)}>
              <p className={css(styles.para, styles.center)}>
                <Translate item={delivery.type} />
                <br /> fait le {moment(delivery.delivery_date).format("D-M-Y")}
              </p>
            </Col>
            <Col xs={12} md={3} className={css(styles.col, styles.center)}>
              <p className={css(styles.para, styles.center)}>
                Récupération à partir de :{" "}
                {delivery.end_at
                  ? moment(delivery.end_at).format("H:mm")
                  : "NC"}
              </p>
            </Col>
            {delivery.recover_information && (
              <Col xs={12} className={css(styles.col, styles.comment)}>
                Commentaire Moulin : {delivery.recover_information}
              </Col>
            )}
          </Row>
        </Container>
      ) : (
        <Container
          className={css(styles.recovery, {
            border: filters.showDelivery === delivery.id && "3px green solid",
          })}
          name={"delivery-" + delivery.id}
          fluid
        >
          <Row className={css(styles.verticalAlign)}>
            {delivery.image == null ? (
              <Col xs={12} md={2} className={css(styles.col, styles.noImg)}>
                Pas d'image
                <UrlQuotation url={delivery.info.quotation_v1_url} old={true} />
                <UrlQuotation url={delivery.info.quotation_url} />
              </Col>
            ) : (
              <Col
                xs={12}
                md={2}
                className={css(styles.col, styles.img, {
                  backgroundImage:
                    'url("' + getUrl + "/" + delivery.image.path + '")',
                })}
              >
                <UrlQuotation url={delivery.info.quotation_v1_url} old={true} />
                <UrlQuotation url={delivery.info.quotation_url} />
              </Col>
            )}
            <Col xs={12} md={4} className={css(styles.col)}>
              <h3 className={css(styles.statut)}>Récupération</h3>
              <p className={css(styles.para)}>
                <span className={css(styles.company)}>{delivery.company}</span>
                <br />
                {delivery.street_number} {delivery.street}{" "}
                {delivery.postal_code} {delivery.city}
                <br />
                <i>{delivery.address_complement}</i>
                <br />
                <br />
                <b>
                  {delivery.firstname} {delivery.lastname}
                </b>
                <br />
                {delivery.phone}
              </p>
            </Col>
            <Col xs={12} md={3} className={css(styles.col)}>
              <p className={css(styles.para, styles.center)}>
                <Translate item={delivery.type} />
                <br />
                fait le{" "}
                {moment(filters.date).format("YYYY-MM-DD") !==
                moment(delivery.delivery_date).format("YYYY-MM-DD") ? (
                  <span className={css(styles.warning)}>
                    {" "}
                    {moment(delivery.delivery_date).format("D-M-Y")}
                  </span>
                ) : (
                  " " + moment(delivery.delivery_date).format("D-M-Y")
                )}
              </p>
            </Col>
            <Col xs={12} md={3} className={css(styles.col, styles.center)}>
              <Reviews note={delivery.note} />
              <p className={css(styles.para, styles.center)}>
                Récupération à partir de :
              </p>
              <UpdateDataApi
                value={delivery.end_at}
                id={delivery.id}
                name={"end_at"}
                api={"delivery"}
                input={"times"}
                handler={handler}
              />
            </Col>
            <Col xs={12} className={css(styles.col, styles.comment)}>
              <UpdateDataApi
                value={delivery.recover_information}
                id={delivery.id}
                name={"recover_information"}
                api={"delivery"}
                input={"text"}
                placeholder={"Ecrire un commentaire..."}
                handler={handler}
              />
            </Col>
          </Row>
        </Container>
      )}
    </div>
  );
};

const styles = {
  recovery: {
    background: "#2e3032",
    margin: "10px 0",
    color: "white",
    transition: "300ms",
    border: "1px #dedede solid",
  },
  recoveryPrint: {
    background: "white",
    margin: "10px 0",
    color: "black",
    transition: "300ms",
    border: "1px #dedede solid",
  },
  statut: {
    textTransform: "uppercase",
    fontSize: "0.6rem",
    marginBottom: "0rem",
    "@media print": {
      fontSize: "1.4rem",
    },
  },
  col: {
    padding: "0.5rem",
    borderRight: "1px #dedede solid",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
  },
  verticalAlign: {
    display: "flex",
    flexDirection: "row",
  },
  para: {
    marginBottom: "0rem",
    fontSize: "0.8rem",
    "@media print": {
      fontSize: "1.6rem",
    },
    "@media (max-width: 767px)": {
      textAlign: 'left'
    },
  },
  center: {
    textAlign: "center",
  },
  noImg: {
    backgroundColor: "#dedede",
    textAlign: "center",
    color: "#ccc",
    wordBreak: "break-word",
  },
  img: {
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    minHeight: "100px",
    textAlign: "center",
  },
  divisor: {
    height: "1px",
    backgroundColor: "#dedede",
    margin: "1.0rem 0",
  },
  blockColor: {
    height: "25px",
    width: "25px",
    position: "absolute",
    top: "0",
    left: "0",
    color: "white",
    textShadow: "1px 1px 2px grey",
    textAlign: "center",
  },
  blockColorPrint: {
    height: "25px",
    width: "25px",
    position: "absolute",
    top: "0",
    right: "0",
    color: "white",
    textShadow: "1px 1px 2px grey",
    textAlign: "center",
    "@media print": {
      fontSize: "2.2rem",
    },
  },
  comment: {
    borderTop: "1px #dedede solid",
    fontSize: "0.8rem",
    "@media print": {
      fontSize: "1.6rem",
    },
  },
  warning: {
    color: "red",
  },
  company: {
    textTransform: "uppercase",
    color: "#429853",
    fontWeight: "bold",
    fontSize: "0.8rem",
    "@media print": {
      fontSize: "1.6rem",
    },
  },
  reallocation: {
    position: "absolute",
    bottom: 0,
    right: 0,
    fontSize: "0.8rem",
  },
};

export default Recovery;
