import React, {useEffect, useState} from 'react'
import { css } from 'emotion'
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment'
import {Button} from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'

import { useFormik } from 'formik';
import * as Yup from 'yup';

import Search from 'app/orderTool/components/sidebar/filters/types/Search'
import DatePicker from "react-datepicker";
import Select from 'app/orderTool/components/sidebar/filters/types/Select'
import Checkbox from 'app/orderTool/components/sidebar/filters/types/Checkbox'
import GenerateProdCardButton from 'app/orderTool/components/sidebar/filters/types/GenerateProdCardButton'

import { useOrders } from 'app/orderTool/context/OrdersProvider'
import LeMoulinApi from 'app/universal/utils/LeMoulinApi'

const Filters = (props) => {

  const orders = useOrders();

  const {
    coreData
  } = props

  const [init, setInit] = useState(false);
  const [tenders, setTenders] = useState([    
  {
    "option"      : "all",
    "traduction"  : "Appel d'offre inclus"
  }]);

  useEffect(() => {
    if (init === false) {
      const getTenders = async () => {
        return LeMoulinApi.request.tender.gets().then((r) => {
          return r.map((tender) => {
            return {
              "option"    : tender.id,
              "traduction": tender.name
            }
          });
        });
      };
      getTenders().then((r) => {
        let tt = [
          {
            "option"      : "all",
            "traduction"  : "Appel d'offre inclus"
          }
        ];
        setTenders(tt.concat(r));
      });
      setInit(true);
    }
  }, [init]);

  // Initialisation de la date quand elle vient de prm url pour le champ type date
  const initDate = (date) => {

    if(orders.dataFilter[date] === undefined ){
      return;
    }

    return new Date(orders.dataFilter[date]);
  }

  const formik = useFormik({
    initialValues: {
      search: orders.dataFilter.search,
      type: orders.dataFilter.type,
      startDate: initDate('startDate'),
      endDate: initDate('endDate'),
      format: orders.dataFilter.format,
      status: orders.dataFilter.status,
      startDatePaid: initDate('startDatePaid'),
      endDatePaid: initDate('endDatePaid'),
      price: orders.dataFilter.price,
      chorus: orders.dataFilter.chorus,
      orderBy: orders.dataFilter.orderBy,
      offset: orders.dataFilter.offset,
      tender_id: undefined,
      is_cancel: orders.dataFilter.is_cancel
    },
    validationSchema: Yup.object({
    }),
    onSubmit: values => {

      // On formate la date pour l'envoyé au bon format au provider
      const formatDate = (date) => {

        if(date === null || date === undefined){
          return undefined;
        }

        return moment(date).format('YYYY-MM-DD');
      }

      let startDate = formatDate(values.startDate)
      let endDate = formatDate(values.endDate)

      const startDatePaid = formatDate(values.startDatePaid)
      const endDatePaid = formatDate(values.endDatePaid)

      if (values.search === ''){
        values.search = undefined;
      }

      if (values.format === 'all'){
        values.format = undefined;
      }

      if (values.chorus ===  undefined){
        values.chorus = false;
      }

      if (values.is_cancel ===  undefined){
        values.is_cancel = false;
      }

      if (values.type === 'quotation'){
        values.orderBy = 'ASC';
      }
      else{
        values.orderBy = 'DESC';
      }

      if (values.tender_id === 'all'){
        values.tender_id = undefined;
      }

      orders.setDataFilter(
        {
          search: values.search,
          type: values.type,
          startDate: startDate,
          endDate: endDate,
          format: values.format,
          status: values.status,
          price: values.price,
          chorus: values.chorus,
          startDatePaid: startDatePaid,
          endDatePaid: endDatePaid,
          orderBy: values.orderBy,
          offset : 0,
          tender_id: values.tender_id,
          is_cancel: values.is_cancel
        });
      },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Search
        id="search"
        name="search"
        type="text"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.search}
        placeholder="Rechercher ..."
      />
      {formik.touched.search && formik.errors.search ? (
        <div>{formik.errors.search}</div>
      ) : null}


      <div className={css(style.col2)}>
        <Button className={css(style.buttonDouble)} active={formik.values.type === 'quotation'}
          onClick={
            () => {formik.setFieldValue('type', 'quotation');formik.setFieldValue('status', 'all_valid')}
          }>Devis</Button>
        <Button className={css(style.buttonDouble)} active={formik.values.type === 'invoice'}
          onClick={
            () => {formik.setFieldValue('type', 'invoice');formik.setFieldValue('status', 'all_invoice')}
          }>Facture</Button>
      </div>

      <div className={css(style.dateRange)}>
        <DatePicker
          placeholderText="Date début"
          selected={formik.values.startDate}
          dateFormat="dd/MM/yyyy"
          name="startDate"
          isClearable
          onChange={date => formik.setFieldValue('startDate', date)}
          className={css(style.dateStart)}
          showMonthDropdown
          showYearDropdown
        />
        <DatePicker
          placeholderText="Date fin"
          selected={formik.values.endDate}
          dateFormat="dd/MM/yyyy"
          name="endDate"
          isClearable
          popperPlacement="bottom-end"
          onChange={date => formik.setFieldValue('endDate', date)}
          className={css(style.dateEnd)}
          showMonthDropdown
          showYearDropdown
        />
      </div>

      <Select
        theme="grey"
        id="format"
        name="format"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.format}
        data={coreData.format}
      />

      <Select
        theme="grey"
        id="tender_id"
        name="tender_id"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.tender_id}
        data={tenders}
      />

      <Select
        id="status"
        name="status"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.status}
        data={formik.values.type === 'invoice' ? coreData.statusInvoice : coreData.statusQuotation}
      />

      <Checkbox
        label={"Client à facturer sur Chorus"}
        name="chorus"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.chorus}
      />

      <Checkbox
        label={"Annulées uniquement"}
        name="is_cancel"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.is_cancel}
      />

      {formik.values.type === 'invoice' &&
        <div>
          <p className={css(style.title)}>Date réglement</p>
          <div className={css(style.dateRange)}>
            <DatePicker
              placeholderText="Date début"
              selected={formik.values.startDatePaid}
              dateFormat="dd/MM/yyyy"
              name="startDatePaid"
              onChange={date => formik.setFieldValue('startDatePaid', date)}
              className={css(style.dateStart)}
            />
            <DatePicker
              placeholderText="Date fin"
              selected={formik.values.endDatePaid}
              dateFormat="dd/MM/yyyy"
              name="endDatePaid"
              popperPlacement="bottom-end"
              onChange={date => formik.setFieldValue('endDatePaid', date)}
              className={css(style.dateEnd)}
            />
          </div>

          <Search
            id="price"
            name="price"
            type="text"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.price}
            placeholder="Prix HT de la commande"
          />
        </div>
      }

      <div className={css(style.center)}>
        <button type="submit" className={css(style.button)}><FontAwesomeIcon icon={faSearch} /> Lancer la recherche </button>
      </div>

      { formik.values.type === 'quotation' && <GenerateProdCardButton value={formik.values}/> }

    </form>
  );
};


const style = {
  buttonCard:{
    backgroundColor: '#dedede !important',
    border: 'none',
    fontSize: '0.9rem',
    marginTop: '4.0rem',
    padding: '0.5rem',
    width: '80%',
    color: 'black !important',
    borderRadius: '0',
    marginBottom: '2.0rem',
    '&:hover': {
      backgroundColor:'#222d32 !important',
      color: 'white !important',
    }
  },
  button:{
    margin: '0',
    color:'white',
    padding: '0.5rem 1rem',
    borderRadius: '0',
    fontSize: '1rem',
    transition: '300ms',
    backgroundColor: '#323232 !important',
    border: 'none',
    borderColor: 'inherit',
    borderBottom: '1px solid #eaeae9',
    width: '100%',
    ':hover': {
      border: 'none',
      backgroundColor: '#616161 !important',
      borderBottom: '1px solid #eaeae9',
    },
    ':focus': {
      border: 'none',
      backgroundColor: '#616161 !important',
      borderBottom: '1px solid #eaeae9',
    }
  },
  col2:{
    border: 'none',
    borderBottom: '2px solid #eaeae9',
    padding: '0',
    width: '100%',
    display: 'flex'
  },
  buttonDouble:{
    padding: '0.5rem 0rem',
    borderRadius: '0',
    fontSize: '0.8rem',
    backgroundColor: '#f8f8f6 !important',
    color: 'black',
    border: 'none',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    width: '100%',
    ':hover':{
      color: 'white',
      backgroundColor: '#439b5c !important',
    },
    '&.active': {
      color: 'white',
      backgroundColor: '#439b5c !important',
    }
  },
  center:{
    textAlign: 'center'
  },
  dateRange:{
    border: 'none',
    borderBottom: '2px solid #eaeae9',
    padding: '0.5rem 0rem',
    width: '100%',
    display: 'flex'
  },
  dateStart:{
    padding: '0rem 0.5rem',
    background: 'none',
    border: 'none',
    color: 'black',
    width: '100%',
    borderRight: '2px solid #eaeae9',
    ':focus':{
      outline : '0'
    },
  },
  dateEnd:{
    padding: '0rem 0.5rem',
    background: 'none',
    border: 'none',
    color: 'black',
    width: '100%',
    borderRight: '2px solid #eaeae9',
    ':focus':{
      outline : '0'
    },
  },
  title:{
    textTransform: 'uppercase',
    margin: '0',
    fontWeight: 'bold',
    fontSize: '0.8rem',
    backgroundColor: '#f8f8f6',
    padding: '0.5rem',
    textAlign: 'center'
  },

}

export default Filters
