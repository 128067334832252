import React from 'react'
import { ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.min.css';

import DesktopMenu from 'app/universal/layout/DesktopMenu'
import MobileMenu from 'app/universal/layout/MobileMenu'
import { useWindowDimensions } from 'app/universal/utils/WindowDimensionsProvider'

const Layout = (props) => {

    const { width } = useWindowDimensions();

    return (
      !props.public ?
        <div id="outer-container">
          <header className='no-print'>
            {width < 890 &&
              <MobileMenu outerContainerId={'outer-container'} pageWrapId={'page-wrap'}/>
            }
              <DesktopMenu />

          </header>
          <main id="page-wrap">
            {props.children}
          </main>
          <ToastContainer
            position="bottom-right"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop
            closeOnClick
            rtl={false}
            pauseOnVisibilityChange
            draggable={false}
            pauseOnHover
            className='no-print'
          />
        </div>
      :
        <>{props.children}</>
    )

}

export default Layout
